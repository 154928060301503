<template>
<div>

  <div class="table-page-search-wrapper">
      <a-form layout="inline"  class="formInquire">
        <a-row :gutter="48" class="orderTop">
          <a-col :md="7" :sm="12">
            <a-form-item label="省份">
              <a-select @change="selectProvince($event)" v-model="queryParam.provinceCode" placeholder="省份" style="width: 80%">
                <a-select-option :key="item.value" :children="item.children" v-for="item in provinceData" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="城市">
              <a-select  @change="selectCity($event)" v-model="queryParam.cityCode"    placeholder="城市" style="width: 80%">                            
                <a-select-option :key="item.value" v-for="item in cityData" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

           <a-col :md="7" :sm="12">
            <a-form-item label="网点名称">
              <a-input v-model="queryParam.outletsName" placeholder="网点名称" style="width: 80%" />
            </a-form-item>
          </a-col>
         
          <a-col :md="7" :sm="12">
            <a-form-item label="分拨中心">
                <a-select  v-model="queryParam.allocateId" placeholder="分拨中心" style="width: 80%">                             
                  <a-select-option :key="item.id" v-for="item in allocateList" :value="item.id">
                    {{ item.allocateName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
          </a-col>

        </a-row>
      
        <a-row :gutter="48">
            <a-col>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.pageTable.refresh(true)">查询</a-button>
              <a-button style="margin-left: 10px" @click="() => (queryParam = {}, cityData={})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

  <div class="table-operator" style="margin-bottom:15px">
    <div>
    <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="margin-right:15px;display:inline-block;">
      <a-button type="primary" icon="upload">上传二级运费表</a-button>
    </a-upload>
    <a-button icon="download" @click="downloadTemplate()">下载表头</a-button>
    </div>
    <a-button type="primary" icon="download" @click="downloadExcel()" style="float:right">下载二级运费表</a-button>
  </div>

  <s-table ref="pageTable"
    :rowKey="(record) => record.id"
    :columns="columns"
    :data="dataset"
    :pagination="pagination"
    :pageSize="10"
    :scroll="{x: 'max-content'}"
  >
    <a slot="name" slot-scope="text">{{ text }}</a>
    <span slot="operation" slot-scope="text, record">
      <a-popconfirm title="您确认要删除此条记录吗？" ok-text="确认" cancel-text="取消" @confirm="handleRemove(record)">
        <a>删除</a>
      </a-popconfirm>
    </span>
    <span slot="outletsAddress" slot-scope="text">
      <ellipsis :length="30" tooltip>{{ text }}</ellipsis>
    </span>
  </s-table>

  <a-modal
    key="upload"
    title="上传报价表"
    :width="900"
    :footer="null"
    :centered="true"
    :keyboard="false"
    :maskClosable="false"
    :visible="modalUploadVisible"
    @cancel="modalUploadCancel"
  >
    <a-table ref="modalTable" size="middle"
      :rowKey="(record) => record.idx"
      :columns="columns"
      :data-source="modalUploadDataset"
      :scroll="{x: 'max-content'}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
    <a-button style="margin:20px 0 0 92%" type="primary" htmlType="submit" :loading="buttonLoading.importSave" @click="modalUploadSubmit()">保存</a-button>
  </a-modal>

</div>
</template>
<script>
import { STable, Ellipsis } from '@/components'
import { transportSecondList, transportSecondInsert, transportSecondRemove, documentInfo, geoCascaderList, geoAllocateList } from '@/api/quotation'
import { ossFileUrl } from '@/utils/upload'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'provinceName',
    dataIndex: 'provinceName',
    title: '省份',
    align: 'center',
    fixed: 'left',
    width: '100px'
  },
  {
    key: 'cityName',
    dataIndex: 'cityName',
    title: '城市',
    align: 'center',
    fixed: 'left',
    width: '100px'
  },
  {
    key: 'outletsName',
    dataIndex: 'outletsName',
    title: '网点名称',
    align: 'center',
  },
  {
    key: 'outletsAddress',
    dataIndex: 'outletsAddress',
    title: '网点地址',
    align: 'center',
    scopedSlots: { customRender: 'outletsAddress' },
  },
  {
    key: 'allocateName',
    dataIndex: 'allocateName',
    title: '分拨名称',
    align: 'center',
  },
  {
    key: 'allocateDistance',
    dataIndex: 'allocateDistance',
    title: '分拨距离KM',
    align: 'center',
  },
  {
    key: 'transshipmentPrice',
    dataIndex: 'transshipmentPrice',
    title: '转运费KG（元）',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    width: '100px',
    fixed: 'right',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

const excelConfig = {
  "cityName": "地级市",
  "outletsName": "网点名称",
  "outletsAddress": "详细地址",
  "allocateName": "所属分拨",
  "allocateDistance": "到所属分拨实际距离（单位：KM）非必填",
  "transshipmentPrice": "二级转运费（单位：元/kg）",
};

export default {
  name: 'TableList',
  components: {
    STable,
    XLSX,
    Ellipsis,
  },
  data() {
    return {
      columns: columns,
      dataset: parameter => {
        return transportSecondList(Object.assign(parameter, this.queryParam))
          .then(res => {
            if (0 == res.code) {
              return res.result;
            }
          }).catch(e => {
            console.info(e);
            this.$message.error('数据加载失败，请稍后重试');
          });
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      provinceData:{},
      cityData:{},
      allocateList:{},
      modalUploadVisible: false,
      modalUploadDataset: [],
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
      buttonLoading: {
        importSave: false,
      },
    
    };
  },
  mounted() {
    const parameter = {};
    geoCascaderList(parameter)
      .then(res => {
        if (0 == res.code) {
          this.geoCascaderList = res.result;
          this.provinceData = this.geoCascaderList
        }
      }).catch(e => {
        console.info(e)
      }),
      geoAllocateList(parameter)
        .then(res => {

            this.allocateList = res.result                    
        }).catch(e => {
          console.info(e);          
      })   
  },
  methods: {
    beforeUpload(file){
      let that = this;
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {type: 'binary'});
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let result = XLSX.utils.sheet_to_json(worksheet);
          console.log(["xlxs-sheet", result])
          let dateset = [];
          for (let i = 0; i < result.length; i++) {
            let row = {"idx": i};
            let importState = true
            for (let key in excelConfig) {
              let name = excelConfig[key];
              let value = result[i][name];
              if (key == "cityName") {
                let geoInfo = this.geoSearchByName(value);
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value])
                  break;
                }
                row["provinceCode"] = geoInfo.provinceCode;
                row["provinceName"] = geoInfo.provinceName;
                row["cityCode"] = geoInfo.cityCode;
                row["cityName"] = geoInfo.cityName;
              }
              else {
                row[key] = value;
              }
              if (typeof(value) == 'undefined' || (typeof(value) == 'string' && value.trim() == "")) {
                importState = false;
                console.log(["xlxs-break", key, value])
                break;
              }
            }
            if (importState && Object.keys(row).length > 1) {
              dateset.push(row);
            }
          }
          that.modalUploadDataset = dateset;
          that.modalUploadLoad();
      };
      return false;
    },
    geoSearchByName(name){
      for (let i = 0; i < this.geoCascaderList.length; i++) {
        let provinceInfo = this.geoCascaderList[i];
        let cityList = provinceInfo.children;
        for (let j = 0; j < cityList.length; j++) {
          let cityInfo = cityList[j];
          if (cityInfo.label.indexOf(name) !== -1) {
            return {
              "provinceCode": provinceInfo.value,
              "provinceName": provinceInfo.label,
              "cityCode": cityInfo.value,
              "cityName": cityInfo.label,
            }
          }
        }
      }
      return null;
    },
    modalUploadLoad(){
      this.modalUploadVisible = true;
    },
    modalUploadCancel(){
      this.modalUploadVisible = false;
    },
    modalUploadSubmit(e){
      let parameter = {"recordList": this.modalUploadDataset};
      this.buttonLoading.importSave = true;
      transportSecondInsert(parameter)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('保存成功');
            this.modalUploadVisible = false;
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('保存失败');
          }
          this.buttonLoading.importSave = false;
        }).catch(e => {
          console.info(e);
          this.buttonLoading.importSave = false;
          this.$message.error('保存失败，请稍后重试');
        })
    },
    handleRemove(record){
      transportSecondRemove(record)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('删除成功');
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('删除失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('删除失败，请稍后重试');
        })
    },
    downloadTemplate(){
      let parameter = {"type": 102};
      documentInfo(parameter)
        .then(res => {
          if (0 == res.code) {
            window.location.href = ossFileUrl(res.result.document);
          } else {
            this.$message.error('下载失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('下载失败，请稍后重试');
        });
    },
    downloadExcel(){
      if (this.downloadExcelState > 0) {
        return;
      }
      this.downloadExcelState = 1;
      this.downloadExcelDataset = [];

      let downloadExcelTitle = [];
      for (let key in excelConfig) {
        downloadExcelTitle.push(excelConfig[key]);
      }
      this.downloadExcelDataset.push(downloadExcelTitle);

      let parameter = {pageNo: 1, pageSize: 100};
      this.downloadDataset(parameter);
    },
    downloadDataset(parameter) {
      let that = this;
      transportSecondList(Object.assign(parameter, this.queryParam))
        .then(res => {
          if (0 == res.code) {
            const result = res.result.result;
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].cityName,
                result[i].outletsName,
                result[i].outletsAddress,
                result[i].allocateName,
                result[i].allocateDistance,
                result[i].transshipmentPrice,                
              ];
              that.downloadExcelDataset.push(dataset);
            }
            const pageNo = res.result.pageNo;
            const pageSize = res.result.pageSize;
            const totalCount = res.result.totalCount;
            const totalPage = Math.ceil(totalCount / pageSize);
            if (pageNo >= totalPage) {
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset);
              let book = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(book, sheet, '二级运费表');
              XLSX.writeFile(book, '二级运费表.xls');
              that.downloadExcelState = 0
              return;
            }
            parameter.pageNo = pageNo + 1;
            that.downloadDataset(parameter);
          }
        }).catch(e => {
          console.info(e);
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试');
        });
    },
    selectProvince(provinceCode){  
      this.queryParam = {provinceCode:provinceCode}
       for(let i = 0; i < this.geoCascaderList.length; i++) {            
            if(provinceCode == this.geoCascaderList[i].value){                  
                  this.cityData = this.geoCascaderList[i].children                                         
                  break;
            }
       }
    },
    selectCity(cityCode){
       setTimeout(()=>{                        
          console.log('cityCode',cityCode)
        this.queryParam.cityCode = cityCode  
      },200)  
      
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-tbody{
  background-color: #fff;
}
.table-operator{
  display: flex;
  justify-content: space-between;
  >div{
    display: flex;
     >span:nth-child(1){
    display: flex !important;
      /deep/ .ant-upload-list-text {
      background-color: #fff;
      margin-left: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .ant-upload-list-item{
        margin-top: 0 !important;
        }
      }
   }
  }
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
.table-operator{
  /deep/  .ant-upload-list-item{
    margin-top: 5px !important;
    .ant-upload-list-item-info .ant-upload-list-item-name{
      padding-right:10px;
    }
  }
  /deep/ .anticon-close{
    font-size: 15px !important;
  }
}
</style>